<template>
  <div class="what">
    <img class="rictangle__what" src="@/assets/img/rictangle.png" />

    <b-container>
      <b-row align-h="center">
        <div class="what_control">
          <button
            v-for="(item, index) in whats"
            :key="index"
            :class="[index == active ? 'active' : '', 'what_link']"
            @click="updateWhats(index)"
          >
            {{ item.link }}
          </button>
        </div>
      </b-row>

      <b-row align-h="center" align-v="center">
        <b-col cols="12" md="5">
          <div class="what_wrapper">
            <img
              class="what_img"
              :src="require('@/assets/' + whats[active].img)"
              alt=""
            />
          </div>
        </b-col>

        <b-col cols="12" md="7">
          <h2 class="what_title">{{ whats[active].title }}</h2>

          <p class="what_text" v-html="whats[active].text"></p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 0,

      whats: [
        {
          link: "Развитие",
          img: "photo/_S__3494.jpg",
          title: "Развитие",
          text: `
          Вкладываемся в развитие сотрудников. Нам важно, чтобы каждый член команды мог использовать и развивать свои сильные качества. Стремимся к созданию экологичного пространства, где сочетается профессиональная и личностная самореализация. С-МАТЕРИАЛС – это пространство, в котором любимая работа является составной частью полноценной, счастливой жизни.
          `,
        },
        {
          link: "Забота",
          img: "img/aplicant/team.png",
          title: "Забота",
          text: `
          Делаем все, чтобы работа проходила без напряжения, создаем комфортные условия для сотрудников и клиентов. Гарантируем поддержку и знания, которые сами применяем в работе. Обучаем оптимизации бизнес-процессов, повышаем эффективность работников.
          `,
        },
        {
          link: "Карьера",
          img: "img/aplicant/office.png",
          title: "Карьера",
          text: `
          Каждый участник команды ценен. Мы создаем условия и возможности развить компетенции. Каждый сотрудник может выйти на желаемый уровень дохода и реализовать свои карьерные амбиции в С-МАТЕРИАЛС. Именно поэтому особое внимание уделяется сопровождению новых сотрудников во время испытательного периода. Наша система обучения нацелена на профессиональный рост коллектива.
          `,
        },
        {
          link: "Cемья",
          img: "img/aplicant/family.jpg",
          title: "Семья",
          text: `
          Во главе компании стоят люди, для которых семья – не пустое слово. Мы полностью осознаем важность личной жизни человека и стремимся, чтобы компания стала гармоничной частью жизни каждого сотрудника.
          `,
        },
        {
          link: "Традиции",
          img: "img/aplicant/traditions.jpg",
          title: "Традиции",
          text: `
          Наши внутренние традиции позволяют выстроить экологичную обстановку. У нас приятно находиться и работать эффективно. Мы создаем условия для самореализации, в которых каждый сотрудник дает компании максимально возможную ценность.
          `,
        },
      ],
    };
  },
  methods: {
    updateWhats(index) {
      this.active = index;
    },
  },
};
</script>

<style lang="scss">
.what {
  position: relative;
}
.rictangle__what {
  top: 40%;
  left: 100%;

  z-index: 1;
  position: absolute;
  height: 140vh;

  transform: translate(-50%, -50%);
  overflow: hidden;
}
.what_control {
  padding-bottom: 60px;
}
.what_link {
  position: relative;
  z-index: 4;

  color: $gray__ultra_light;
  font-size: 16px;
  font-weight: 400;

  border: 1px solid rgba($color: $gray__medium_light, $alpha: 0.7);
  padding: 3px 15px;
  border-radius: 10px;

  margin: 0 5px;
}
.what_link.active {
  color: $white;
  border-color: $accent;
}
.what_link:focus,
.what_link:focus-visible {
  outline: none;
  box-shadow: none;
}

.what_wrapper {
  position: relative;
  z-index: 4;
  width: 100%;
  height: 480px;

  position: relative;
  overflow: hidden;

  border-radius: 5px;
}
.what_img {
  position: absolute;

  height: 100%;
  width: 100%;

  object-fit: cover;
}

.what_title {
  position: relative;
  z-index: 4;
  color: $accent;
  font-size: 30px;
  font-weight: 500;
  margin: 0;
  padding-bottom: 30px;
}
.what_text {
  position: relative;
  z-index: 4;
  color: $white;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}
</style>
