var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"specialize-block"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"tile-canvas"},[_c('div',{staticClass:"circle-logo-absolute d-none d-lg-block",style:({
          'background-image':
            'url(' + require('@/assets/svg/logo-smaterials.svg') + ')',
        })}),_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-md-6"},[(_vm.img1)?_c('div',{staticClass:"tile_img _left",style:({
              'background-image': 'url(' + require('@/assets/' + _vm.img1) + ')',
            })}):_vm._e()]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"tiles-text"},[(_vm.title1)?_c('h3',{staticClass:"tiles second-title"},[_vm._v(_vm._s(_vm.title1))]):_vm._e(),(_vm.text1)?_c('p',{staticClass:"text"},[_vm._v(_vm._s(_vm.text1))]):_vm._e()]),(_vm.button1 != '')?_c('div',{staticClass:"row justify-content-center"},[_c('button',{attrs:{"id":"button_outline"},on:{"click":_vm.button1Emit}},[_vm._v(" "+_vm._s(_vm.button1)+" ")])]):_vm._e()])]),_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"tiles-text"},[(_vm.title2)?_c('h3',{staticClass:"tiles second-title"},[_vm._v(_vm._s(_vm.title2))]):_vm._e(),(_vm.text2)?_c('p',{staticClass:"text"},[_vm._v(_vm._s(_vm.text2))]):_vm._e()]),(_vm.button2 != '')?_c('div',{staticClass:"row justify-content-center"},[_c('button',{attrs:{"id":"button_outline"},on:{"click":_vm.button2Emit}},[_vm._v(" "+_vm._s(_vm.button2)+" ")])]):_vm._e()]),_c('div',{staticClass:"col-md-6"},[(_vm.img2)?_c('div',{staticClass:"tile_img _right",style:({
              'background-image': 'url(' + require('@/assets/' + _vm.img2) + ')',
            })}):_vm._e()])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }