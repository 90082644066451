<template>
  <div>
    <!-- Header -->
    <div class="applicant-header">
      <img
        class="applicant-header_bg"
        :src="require('@/assets/photo/_S__3491.jpg')"
        alt=""
      />

      <div class="applicant-header_bg-filter"></div>

      <b-container>
        <b-row
          align-h="center"
          align-v="center"
          class="applicant-header_content"
        >
          <b-col cols="12" md="8">
            <h1 class="applicant-header_title header_title">
              С-МАТЕРИАЛС – пространство для профессиональной и личностной
              реализации
            </h1>

            <p class="text_one">
              Мы молодая амбициозная компания, оказывающая услуги на рынке
              тендерных закупок по всей России. Приглашаем на работу
              компетентных и талантливых специалистов, готовых раскрыть свой
              потенциал в дружной и сильной команде.
            </p>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <ApplicantWelcome />

    <LineInfo title="Кому подойдет наша компания?" />

    <!-- Who -->
    <div class="who">
      <b-container>
        <b-row align-h="center" align-v="center">
          <b-col cols="12" md="7">
            <div class="ac-welcome_content">
              <h2 class="who__title block_title">
                Развиваем команду вместе с вами
              </h2>

              <p class="ac-welcome_content__text">
                Мы стремительно растём на рынке тендерного снабжения. Для
                укрепления позиций и поддержания темпов роста нам нужны опытные
                специалисты в сферах тендерной торговли и складского
                обслуживания.<br />
                <br />
                Приглашаем в нашу команду людей, которые стремятся к достижению
                результата, горят идеей нести пользу окружающим, нацелены на
                постоянный рост и развитие. Ценим тех, кто готов решать задачи
                оперативно, ответственно, инициативно. Тех, кто ценит важность
                человеческих отношений и готов быть открытым и честным в команде
                и при работе с клиентами.
              </p>
            </div>
          </b-col>

          <b-col cols="12" md="5">
            <div class="who_wrapper">
              <img
                class="who_wrapper_img"
                :src="require('@/assets/photo/_S__3378.jpg')"
                alt=""
              />
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <KeyWay />

    <Specialize
      img1="img/aplicant/warehouse.png"
      title1="Что делаем?"
      text1="
      Осуществляем комплексные поставки товаров и услуг для крупного российского бизнеса. Спектр поставляемых категорий товаров и услуг обширен: от IT-оборудования и программного обеспечения до мебели и сувенирной продукции.
      "
      img2="img/aplicant/city.jpg"
      title2="С кем работаем?"
      text2="
      Нашими клиентами являются компании федерального уровня: «Норильский никель», «Роснефть», «РН-Ванкор», «РН-Юганскнефтегаз» и другие.
      "
    />

    <LineInfo title="С-МАТЕРИАЛС - пространство реализации" />

    <What />

    <HeaderLogo
      showMap
      showLogoTitle
      title="ГЕОГРАФИЯ КОМПАНИИ"
      text="Осуществляем комплексные тендерные поставки по всей России."
      logo="logo.png"
    />

    <!-- Contact info -->
    <div class="contact-info">
      <img
        id="cloudContactInfo"
        :src="require('@/assets/img/cloud.png')"
        alt=""
      />

      <b-container>
        <b-row align-h="center" align-v="center" class="contact-info_row">
          <b-col>
            <div class="map">
              <iframe
                v-if="contacts && contacts.map"
                :src="contacts.map"
                width="400"
                height="400"
                frameborder="0"
              ></iframe>
            </div>
          </b-col>

          <b-col cols="12" md="7">
            <div class="info">
              <h2 class="block_title">Контактная информация</h2>

              <p v-if="contacts" class="contact-adress">
                {{ contacts.adress }}
              </p>

              <p v-if="contacts" class="contact-phone">
                {{ contacts.phone }}
              </p>

              <p v-if="contacts" class="contact-email">
                {{ contacts.email }}
              </p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <!-- Images -->
    <div>
      <div class="line-title-left">
        <b-container>
          <b-row align-h="between" align-v="start">
            <b-col cols="12" md="auto">
              <h2 class="block_title">Стань частью нашей команды!</h2>
            </b-col>

            <!-- <b-col cols="auto">
              <div class="contact-controls line">
                <img
                  @click="prevImageSlide()"
                  class="contact-control"
                  :src="require('@/assets/img/aplicant/row-left.png')"
                  alt=""
                />
                <img
                  @click="nextImageSlide()"
                  class="contact-control"
                  :src="require('@/assets/img/aplicant/row-right.png')"
                  alt=""
                />
              </div>
            </b-col> -->
          </b-row>
        </b-container>
      </div>

      <div class="images">
        <b-container>
          <b-row>
            <b-col cols="12" md="8">
              <b-row>
                <b-col cols="12" class="mh">
                  <div class="img-canvas-mh">
                    <img
                      class="work_img"
                      :src="
                        require('@/assets/' +
                          imageSlides[activeImageSlide].img[0])
                      "
                    />
                  </div>
                </b-col>

                <b-col cols="12" md="6" class="mh">
                  <div class="img-canvas-mh">
                    <img
                      class="work_img"
                      :src="
                        require('@/assets/' +
                          imageSlides[activeImageSlide].img[1])
                      "
                    />
                  </div>
                </b-col>

                <b-col cols="12" md="6" class="mh">
                  <div class="img-canvas-mh">
                    <img
                      class="work_img"
                      :src="
                        require('@/assets/' +
                          imageSlides[activeImageSlide].img[2])
                      "
                      alt=""
                    />
                  </div>
                </b-col>
              </b-row>
            </b-col>

            <b-col cols="12" md="4">
              <div class="img-canvas-fh">
                <img
                  class="work_img"
                  :src="
                    require('@/assets/' +
                      imageSlides[activeImageSlide].img[3])
                  "
                />
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>

    <Coops />

    <LineInfo
      title="Вакансии и процесс найма"
      description="Узнайте подробнее о процессе найма и всех открытых вакансиях."
    />

    <div class="vac">
      <b-container>
        <b-row>
          <b-col cols="12" md="6">
            <div
              class="vac_card"
              :style="{
                'background-image':
                  'url(' + require('@/assets/img/aplicant/recmProc.jpg') + ')',
              }"
            >
              <h2 class="vac_title">Процесс найма</h2>
              <b-row align-h="center">
                <button
                  @click="$router.push({ name: 'Vacancies' })"
                  id="button_outline"
                >
                  Подробнее
                </button>
              </b-row>
            </div>
          </b-col>

          <b-col cols="12" md="6">
            <div
              class="vac_card"
              :style="{
                'background-image':
                  'url(' + require('@/assets/img/aplicant/vacancy.jpg') + ')',
              }"
            >
              <h2 class="vac_title">Вакансии</h2>

              <b-row align-h="center">
                <button
                  @click="$router.push({ name: 'Vacancies' })"
                  id="button_outline"
                >
                  Подробнее
                </button>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <ApForm :vacancies="vacancies" />

    <LineInfo
      v-bind:title="'Дополнительная информация'"
      v-bind:description="'Держим в курсе актуальных новостей'"
    />

    <MoreInfo />

    <!-- <News /> -->
  </div>
</template>

<script>
import LineInfo from "@/components/LineInfo.vue";
import Specialize from "@/components/Specialize.vue";
import HeaderLogo from "@/components/HeaderLogo.vue";
import ApForm from "@/components/ApForm.vue";
import News from "@/components/News.vue";
import MoreInfo from "@/components/MoreInfo.vue";
import What from "@/components/What.vue";
import Coops from "@/components/Coops.vue";
import ApplicantWelcome from "@/components/ApplicantWelcome.vue";
import KeyWay from "@/components/KeyWay.vue";
import axios from "axios";

export default {
  name: "Applicant",
  components: {
    LineInfo,
    Specialize,
    HeaderLogo,
    ApForm,
    News,
    MoreInfo,
    What,
    Coops,
    ApplicantWelcome,
    KeyWay,
  },
  data() {
    return {
      vacancies: null,
      activeImageSlide: 0,

      contacts: {
        map: `https://yandex.ru/map-widget/v1/?um=constructor%3A69f576e5c818cfefafd3fc8017820b53769d85382a6ac2be9f25621b7e3184ba&amp;source=constructor`,
        adress:
          "ул. Партизана Железняка, 35А, Красноярск, Красноярский край, 660093",
        phone: "+7 (965) 897 05-51",
        email: "info@s-materials.ru",
      },

      imageSlides: [
        {
          img: ["photo/_S__3403.jpg", "photo/_S__3295.jpg", "photo/_S__3563.jpg", "photo/_S__3519.jpg"],
        }
      ],
    };
  },
  methods: {
    prevImageSlide() {
      if (this.activeImageSlide > 0) {
        this.activeImageSlide--;
      }
    },
    nextImageSlide() {
      if (this.imageSlides != "") {
        if (this.activeImageSlide < this.imageSlides.length - 1) {
          this.activeImageSlide++;
        }
      }
    },
  },
  async mounted() {
    try {
      let vacancies = await axios.get(
        "https://api.hh.ru/vacancies?employer_id=5192954"
      );
      this.vacancies = vacancies.data;
    } catch (e) {
      console.error(e);
    }
  },
};
</script>

<style lang="scss">
#rictangle {
  height: 140vh;
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}
#cloudContactInfo {
  position: absolute;
  height: 70vh;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 0);
}

.applicant-header {
  position: relative;
  overflow: hidden;
  margin-bottom: 100px;
  min-height: 80%;
}
.applicant-header_bg {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
  object-fit: cover;
}
.applicant-header_bg-filter {
  background-color: rgba($black, 0.7);
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 3;
}

.applicant-header_content {
  position: relative;
  height: 100vh;
  z-index: 4;
}

.applicant-header_title {
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  padding-bottom: 30px;
}

//Who
.who {
  padding-bottom: 100px;
}

.who_wrapper {
  position: relative;
  height: 445px;
  width: 100%;
}
.who_wrapper_img {
  position: absolute;

  height: 100%;
  width: 100%;
  object-fit: cover;

  z-index: 4;
}

//Vac
.vac {
  padding-bottom: 100px;
}
.vac_card {
  background-repeat: no-repeat;
  background-size: cover;

  border-radius: 10px;
  padding-top: 100px;
  padding-bottom: 100px;
  margin-bottom: 15px;
  background-color: rgba($gray-dark, 0.623);
  background-blend-mode: saturation;
  transition: all 300ms;
}
.vac_card:hover {
  background-color: rgba($gray-dark, 0.523);
  transition: all 300ms;
}
.vac_title {
  text-align: center;
  margin: 0;
  font-weight: 500;
  font-size: 28px;
  padding-bottom: 30px;
}

// Contact
.contact-info {
  position: relative;
  padding-bottom: 100px;
  transition: linear 300ms;
}
.contact-info_row {
  background-color: $gray;
  position: relative;
  z-index: 2;
}
.map {
  background-color: $black;
  height: 400px;
  width: 400px;
}
.map-col {
  padding: 15px;
}
.contact-title {
  color: $white;
  font-weight: 500;
  font-size: 30px;

  padding-bottom: 15px;
}
.contact-controls {
  width: 100%;
  display: flex;
  justify-content: flex-start;

  padding-bottom: 60px;
}
.contact-controls.line {
  padding-bottom: 0px;
}
.contact-control {
  width: 38px;
  height: 38px;
  cursor: pointer;
  margin-right: 20px;
}
.contact-control:hover {
  filter: opacity(90%);
}
.contact-adress {
  margin-bottom: 10px;
  color: $white;
  font-size: 16px;
}
.contact-phone {
  margin-bottom: 10px;
  color: $accent;
  font-weight: 300;
  font-size: 22px;
}
.contact-email {
  margin-bottom: 10px;
  color: $accent;
  font-weight: 300;
  font-size: 22px;
}

.line-title-left {
  position: relative;
  z-index: 2;
  padding-bottom: 30px;
}

.img-canvas-mh {
  height: 250px;
  background-color: $black;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
.img-canvas-fh {
  height: 515px;
  background-color: $black;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
.mh {
  padding-right: 0px;
  padding-bottom: 15px;
}
.images {
  padding-bottom: 100px;
  position: relative;
}

.work_img {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
</style>
