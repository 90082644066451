<template>
  <div class="key-way">
    <line-info title="Ключевые направления деятельности" />
    <img class="rictangle__key-way" src="@/assets/img/rictangle.png" alt="" />

    <b-container>
      <b-row>
        <b-col cols="12">
          <div v-swiper:mySwiper="swiperOption">
            <div class="swiper-wrapper">
              <div
                v-for="(item, index) in data"
                :key="index"
                class="swiper-slide applicant"
              >
                <div class="key-way__wrapper">
                  <img class="key-way__wrapper__img" v-if="item.img" :src="require(`@/assets/svg/${item.img}`)">
                </div>

                <div class="key-way__content">
                  <p class="key-way__text">{{ item.text }}</p>
                </div>
              </div>
            </div>

            <div class="swiper-pagination"></div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import LineInfo from "./LineInfo.vue";
export default {
  components: { LineInfo },
  name: "KeyWay",
  data() {
    return {
      swiperOption: {
        autoHeight: true,
        mousewheel: {
          forceToAxis: true
        },
        autoplay: {
          delay: 3000
        },
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: 15,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 4,
            spaceBetween: 15,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 4,
            spaceBetween: 15,
          },
        },

        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
      },
      data: [
        {
          img: `icon-services-box.svg`,
          text: "Аналитика рынка тендеров, мониторинг и поиск конкурсов",
        },
        {
          img: `icon-signature.svg`,
          text: "Подготовка конкурсной документации, просчет смет",
        },
        {
          img: `icon-like-peoples.svg`,
          text: "Работа с заказчиком и поставщиками",
        },
        {
          img: `icon-cart-boxes.svg`,
          text: "Логистика товара, складское обслуживание ",
        },
        {
          img: `icon-pencil-paper.svg`,
          text: "Выполнение строительных работ",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.key-way {
  position: relative;
  margin-bottom: 100px;
}
.rictangle__key-way {
  top: 80%;
  left: 0%;

  z-index: 1;
  position: absolute;
  height: 140vh;

  transform: translate(-50%, -50%);
  overflow: hidden;
}

.swiper-slide.applicant {
  width: 100%;
  height: auto;
  padding-right: 0;
  padding-left: 0;
}

.key-way__wrapper {
  background-color: $gray;

  height: 210px;
  width: 100%;
  overflow: hidden;
  position: relative;

  border-radius: 10px;
}
.key-way__wrapper__img {
  height: 100%;
  width: 100%;

  position: absolute;
  object-fit: contain;

  padding: 40px;
  margin-bottom: 15px;
}

@media only screen and (max-width: 640px) {
  .key-way__wrapper {
    background-color: $gray;

    width: 100%;
    overflow: hidden;
    position: relative;

    border-radius: 10px;
  }

  .key-way__wrapper__img {
    height: 100%;
    width: 100%;

    position: absolute;
    object-fit: contain;

    padding: 20px;
    margin-bottom: 15px;
  }
}

.key-way__content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  margin-top: 15px;
}
.key-way__text {
  font-size: 16px;
  color: $white;
  font-weight: 400;

  text-align: center;
}
</style>
