<template>
  <div class="coops-block">
    <!-- <line-info title="Наши сотрудники" /> -->

    <img id="rictangle" src="@/assets/img/rictangle.png" alt="" />

    <!-- <b-container>
      <b-row class="coops-carousel_canvas">
        <div v-swiper:mySwiperApl="swiperOption">
          <div class="swiper-wrapper slider_coops__wrapper">
            <div class="swiper-slide applicant" v-for="(item, index) in coops" :key="index">
              <div class="img-coop_canvas" v-b-modal.coop @click="updateCoopModal(index)">
                <img
                  class="img-coop_img"
                  :src="require('@/assets/img/aplicant/coops/' + item.img)"
                  alt=""
                />
              </div>
              <div class="coop_text-canvas">
                <h3 class="coop_name">{{ item.name }}</h3>
                <p class="coop_about">{{ item.position }}</p>
              </div>
            </div>
          </div>

          <div class="swiper-pagination"></div>
        </div>
      </b-row>
    </b-container> -->

    <b-modal
      id="coop"
      hide-footer
      hide-header
      size="xl"
      centered
      body-class="coop_modal__body"
      content-class="coop_modal__content"
    >
      <b-container>
        <button class="partner_modal__close" type="button" @click="close">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 5L5 19M5.00001 5L19 19"
              stroke="#fff"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>

        <b-row align-h="center" align-v="center">
          <b-col cols="12">
            <b-row>
              <b-col cols="12" md="5" class="img-col-modal">
                <div class="img-coop-modal_canvas">
                  <img
                    v-if="coops[activeCoopModal].img"
                    class="img-coop-modal_img"
                    :src="
                      require('@/assets/img/aplicant/coops/' +
                        coops[activeCoopModal].img)
                    "
                    alt=""
                  />
                </div>
              </b-col>

              <b-col cols="12" md="7" class="modal-col-text">
                <h3 class="coop-modal_name">
                  {{ coops[activeCoopModal].full_name }}
                </h3>

                <p class="coop-modal_text" v-if="coops[activeCoopModal].date">
                  <span class="coop-modal_subtitle">Дата рождения:</span
                  ><br />{{ coops[activeCoopModal].date }}
                </p>

                <p class="coop-modal_text" v-if="coops[activeCoopModal].work">
                  <span class="coop-modal_subtitle">Работает с:</span><br />{{
                    coops[activeCoopModal].work
                  }}
                </p>

                <p
                  class="coop-modal_text"
                  v-if="coops[activeCoopModal].position"
                >
                  <span class="coop-modal_subtitle">Должность:</span><br />{{
                    coops[activeCoopModal].position
                  }}
                </p>

                <p
                  class="coop-modal_text"
                  v-if="coops[activeCoopModal].superSkill"
                >
                  <span class="coop-modal_subtitle">Суперсила:</span><br />{{
                    coops[activeCoopModal].superSkill
                  }}
                </p>

                <p class="coop-modal_text" v-if="coops[activeCoopModal].hobby">
                  <span class="coop-modal_subtitle">Хобби:</span><br />{{
                    coops[activeCoopModal].hobby
                  }}
                </p>

                <p class="coop-modal_text" v-if="coops[activeCoopModal].family">
                  <span class="coop-modal_subtitle">Семейное положение:</span
                  ><br />{{ coops[activeCoopModal].family }}
                </p>

                <span
                  class="coop-modal_subtitle"
                  v-if="coops[activeCoopModal].additional"
                >
                  Почему я работаю в С-МАТЕРИАЛС:
                </span>

                <p
                  v-html="coops[activeCoopModal].additional"
                  class="coop-modal_text"
                  v-if="coops[activeCoopModal].additional"
                ></p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import LineInfo from "./LineInfo.vue";
export default {
  components: { LineInfo },
  name: "Coops",
  data() {
    return {
      activeCoopModal: 0,

      coops: [
        {
          img: "yoolia.png",
          name: "Тагирова Ю. Ф.",
          full_name: "Тагирова Юлия Фаритовна",
          date: "04.07.1985",
          work: "сентября 2020",
          position: "Менеджер по закупкам",
          superSkill:
            "Стрессоустойчивость, выполнение задач в срок, коммуникабельность.",
          hobby: "Спорт, танцы, рисование, дизайн интерьера.",
          family: "Не замужем.",
          additional: `Политика и ценности компании, возможности карьерного роста, возможности дополнительного обучения, масштабные и интересные задачи / проекты.`,
        },
        {
          img: "michail.png",
          name: "Савинов М. В.",
          full_name: "Савинов Михаил Вадимович",
          date: "03.04.1996",
          work: "18 февраля 2021",
          position: "Менеджер тендерных продаж",
          superSkill:
            "Ответственность, целеустремлённость, коммуникабельность.",
          hobby: "Занятия спортом, путешествия, активный образ жизни, рыбалка.",
          family: "Холост",
          additional: `
          Интересна сфера деятельности; классный коллектив, интересные задачи, возможность профессионального обучения, карьерный рост.
          `,
        },
        {
          img: "ivan.jpeg",
          name: "Серебряков И. А.",
          full_name: "Серебряков Иван Андреевич",
          date: "27.06.1998",
          work: "июля 2020",
          position: "Менеджер",
          superSkill: "Системность, постоянная готовность к сложным задачам.",
          hobby: "Спорт, вышивание крестиком.",
          family: "Женат",
          additional: `
          1. Грамотные руководители / лидеры, у которых всегда есть чему учиться, и которые вкладываются в своих сотрудников<br />
          2. Перспективы роста, которые я вижу.<br />
          3. Возможность самореализации. Для меня это важная вещь, я вижу, что моим амбициям есть где развернуться<br />
          4. Здоровый климат в коллективе.<br />
          `,
        },
        {
          img: "dmitriy.jpeg",
          name: "Пискунов Д. В.",
          full_name: "Пискунов Дмитрий Викторович",
          date: "21.05.1995",
          work: "8 апреля 2021",
          position: "Сопровождение товара",
          superSkill:
            "Работа в режиме мультизадачности, оперативность, ответственность.",
          hobby: "Общение с друзьями, видеоигры, просмотр ютуба.",
          family: "Холост",
          additional: `Возможность развиваться и реализовать себя.<br />
           Компания привлекла меня разнообразием поставляемых товаров, разнообразием поставленных задач и возможностью развиваться в большом количестве направлений.
          `,
        },
      ],

      swiperOption: {
        cssMode: true,
        autoHeight: true,
        mousewheel: true,
        keyboard: {
          enabled: true,
          onlyInViewport: true,
        },
        // Responsive breakpoints
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: 15,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 4,
            spaceBetween: 15,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 4,
            spaceBetween: 15,
          },
        },

        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
      },
    };
  },
  methods: {
    close() {
      this.$bvModal.hide("coop");
    },
    updateCoopModal(index) {
      this.activeCoopModal = index;
    },
  },
};
</script>

<style lang="scss">
.img-coop_canvas {
  height: 180px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;

  margin-bottom: 15px;
}
.img-coop_img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.coops-block {
  position: relative;
  padding-bottom: 100px;
}

.coop_name {
  color: $white;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  margin-bottom: 5px;
}
.coop_about {
  color: rgba($white, 0.5);
  font-size: 14px;
  text-align: center;
  margin: 5px;
}
.coops-carousel_canvas {
  overflow: hidden;
  position: relative;
  z-index: 2;
}
.coops-carousel_line {
  display: flex;
  transition: all ease 700ms;
}

// modal
.img-coop-modal_canvas {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.img-coop-modal_img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.coop-modal_bg {
  background-color: $gray;
}
.img-col-modal {
  padding-left: 0;
}
.modal-col-text {
  padding-top: 30px;
  padding-bottom: 30px;
}
.coop-modal_name {
  color: $accent;
}
.coop-modal_subtitle {
  font-weight: 400;
  color: rgba($white, 0.7);
  font-size: 14px;
}
.coop-modal_text {
  color: $white;
  font-weight: 300;
  font-size: 16px;
}

.slider_coops__wrapper {
  margin-bottom: 30px;
}

.modal-body.coop_modal__body {
  background-color: unset;
  background-color: rgba($color: $gray, $alpha: 0.9);
  border-radius: 15px;
  border: 1px solid rgba($color: $accent, $alpha: 0.5);
  padding: 60px;
}
.modal-content.coop_modal__content {
  background-color: unset;
  border-radius: 15px;
  border: 0;
}
</style>
