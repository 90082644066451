<template>
  <div class="specialize-block">
    <div class="container">
      <div class="tile-canvas">
        <div
          class="circle-logo-absolute d-none d-lg-block"
          :style="{
            'background-image':
              'url(' + require('@/assets/svg/logo-smaterials.svg') + ')',
          }"
        ></div>

        <div class="row align-items-center">
          <div class="col-md-6">
            <div
              v-if="img1"
              class="tile_img _left"
              :style="{
                'background-image': 'url(' + require('@/assets/' + img1) + ')',
              }"
            ></div>
          </div>
          <div class="col-md-6">
            <div class="tiles-text">
              <h3 v-if="title1" class="tiles second-title">{{ title1 }}</h3>
              <p v-if="text1" class="text">{{ text1 }}</p>
            </div>
            <div v-if="button1 != ''" class="row justify-content-center">
              <button @click="button1Emit" id="button_outline">
                {{ button1 }}
              </button>
            </div>
          </div>
        </div>

        <div class="row align-items-center">
          <div class="col-md-6">
            <div class="tiles-text">
              <h3 v-if="title2" class="tiles second-title">{{ title2 }}</h3>
              <p v-if="text2" class="text">{{ text2 }}</p>
            </div>
            <div v-if="button2 != ''" class="row justify-content-center">
              <button @click="button2Emit" id="button_outline">
                {{ button2 }}
              </button>
            </div>
          </div>

          <div class="col-md-6">
            <div
              v-if="img2"
              class="tile_img _right"
              :style="{
                'background-image': 'url(' + require('@/assets/' + img2) + ')',
              }"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    img1: String,
    title1: String,
    text1: String,
    button1: {
      type: String,
      default: "",
    },

    img2: String,
    title2: String,
    text2: String,
    button2: {
      type: String,
      default: "",
    },
  },
  methods: {
    button1Emit() {
      this.$emit("button1Emit");
    },
    button2Emit() {
      this.$emit("button1Emit");
    },
  },
};
</script>

<style lang="scss">
.tile_img {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  min-height: 320px;
}
.second-title {
  color: $accent;
  font-weight: 500;
  font-size: 20px;
  margin: 0;
}
.tiles.second-title {
  padding-bottom: 30px;
}
.tiles-text {
  padding-left: 10%;
  padding-right: 10%;
}
.tile-canvas {
  position: relative;
}

.specialize-block {
  background-color: $gray;
  margin-bottom: 100px;
}
.circle-logo-absolute {
  height: 132px;
  width: 132px;
  border-radius: 100%;

  background-color: $gray-dark;
  box-shadow: 0 0 13px 3px rgba($black, 0.16);

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  background-position: 50% 63%;
  background-size: 60%;
  background-repeat: no-repeat;
}
#logo-medal {
  width: 64%;
}
</style>
