<template>
  <!-- Welcome -->
  <div class="ac-welcome">
    <img
      class="rictangle__ac-welcome"
      src="@/assets/img/rictangle.png"
      alt=""
    />

    <b-container>
      <b-row align-v="center">
        <b-col cols="12" md="5">
          <div class="ac-man_wrapper">
            <img
              class="ac-man_img"
              :src="require('@/assets/img/aplicant/company.png')"
              alt=""
            />
          </div>
        </b-col>

        <b-col cols="12" md="7">
          <div class="ac-welcome_content">
            <h2 class="who__title block_title">
              Приветствие от учредителей компании
            </h2>

            <p class="ac-welcome_content__text">
              Станьте частью команды в С-МАТЕРИАЛС и позвольте раскрыться своему
              потенциалу!<br />
              <br />
              С-МАТЕРИАЛС - это больше чем работа, мы заботимся о гармоничном
              развитии каждого сотрудника и создаём среду, где хочется быть
              продуктивным и в которой сохраняется баланс во всех сферах
              жизни.<br />
              <br />
              Команда - это вторая семья, у которой общие цели, и все работают
              на общее благо. Для нас важен каждый член команды.<br />
              <br />
              Мы убеждены, что именно гармоничное развитие и занятие делом,
              которое нравится, раскрывают максимальную ценность каждого из нас.
              Чем большую пользу человек несет в этот мир, тем он счастливее и
              успешнее. Именно поэтому мы со всей ответственностью подходим к
              созданию комфортной рабочей и развивающей среды в компании.<br />
              <br />
              С уважением Константин Миронов.
            </p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "ApplicantWelcome",
  data() {
    return {
      swiperOption: {
        cssMode: true,
        autoHeight: true,
        mousewheel: true,
        keyboard: {
          enabled: true,
          onlyInViewport: true,
        },
        // Responsive breakpoints
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: 15,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 4,
            spaceBetween: 15,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 4,
            spaceBetween: 15,
          },
        },

        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
      },
    };
  },
};
</script>

<style lang="scss">
.ac-welcome {
  padding-bottom: 100px;
  position: relative;
}

.rictangle__ac-welcome {
  top: 70%;
  left: 100%;

  z-index: 1;
  position: absolute;
  height: 140vh;

  transform: translate(-50%, -50%);
  overflow: hidden;
}

.ac-man_wrapper {
  height: 500px;
  width: 100%;

  position: relative;
  overflow: hidden;

  border-radius: 0 20px 140px 0;
}

.ac-welcome_content {
  padding-left: 30px;
  padding-right: 30px;
  z-index: 4;
  position: relative;
}
.ac-welcome_content__text {
  font-size: 16px;
  color: $white;
  margin: 0;
  z-index: 4;
  position: relative;
}
</style>
